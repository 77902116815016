"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledInnerContainer = exports.GlobalStyle = void 0;
const react_native_1 = require("react-native");
const FlexRow = {
    flexDirection: "row",
};
const SpaceBetween = {
    justifyContent: "space-between",
};
const Pt3 = {
    paddingTop: 16,
};
exports.GlobalStyle = react_native_1.StyleSheet.create({
    outerContainer: {
        overflow: "scroll",
        flex: 1,
        height: "100%",
        width: "100vw",
        backgroundColor: "black",
    },
    innerContainer: {
        padding: 8,
        flex: 1,
        display: "flex",
        margin: "auto",
        height: react_native_1.Dimensions.get("window").height,
    },
    lightBackground: {
        backgroundColor: "#00A54B15",
    },
    rounded: {
        borderRadius: 16,
    },
    padded: {
        padding: 16,
    },
    margined: {
        margin: 16,
    },
    // contentBox: {
    //   padding: 16,
    //   backgroundColor: "#212121",
    //   borderRadius: 16,
    //   marginBottom: 8,
    // },
    actionButtonColor: {
        color: "#00A54B",
    },
    flexRow: FlexRow,
    spaceBetween: SpaceBetween,
    pt3: Pt3,
    headerBar: {
        ...FlexRow,
        ...SpaceBetween,
        ...Pt3,
    },
    mr3: {
        marginRight: "16px",
    },
    header: {
        height: "70px",
        backgroundColor: "white",
        borderLeftColor: "#F5F6F8",
        borderLeftWidth: 1,
        padding: "16px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    headerButton: {
        height: "40px",
    },
    deleteButton: {
        color: "red",
    },
    // h1: {
    //   fontWeight: "bold",
    //   fontSize: 28,
    //   marginBottom: "32px",
    //   marginTop: 4,
    //   color: "white",
    // },
    shadow: {
        shadowColor: "#00A54B",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    // input: {
    //   marginTop: "4px",
    //   marginBottom: "16px",
    //   padding: "8px",
    //   height: "44px",
    //   borderColor: "#121212",
    //   borderWidth: 1,
    //   backgroundColor: "#212121",
    //   borderRadius: 4,
    //   color: "white",
    // },
    // text: {
    //   color: "white",
    // },
});
exports.StyledInnerContainer = [
    exports.GlobalStyle.innerContainer,
    exports.GlobalStyle.padded,
];
