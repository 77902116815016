"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const utils_1 = require("../utils/utils");
const firestore_1 = require("../types/firestore");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_2 = require("firebase/firestore");
const alert_1 = require("../shared/alert");
class CalendarStore {
    calendarEvents = [];
    selectedCalendarEvent;
    isNewCalendarEvent = false;
    hasOpenRequests = false;
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("CalendarStore constructor", this.rootStore);
    }
    async sendSchedulingNotification() {
        this.rootStore.isLoading = true;
        let error_;
        try {
            const result = await (0, index_web_1.requestOnCall)('sendSchedulingNotifications');
            this.rootStore.isLoading = false;
            if (result.success && result.message) {
                (0, alert_1.StdAlert)('Success', result.message);
            }
            else {
                error_ = "Something went wrong... 🤷🏻‍♂️";
            }
        }
        catch (error) {
            error_ = error;
        }
        if (error_) {
            (0, alert_1.StdAlert)('Error', `${error_}`);
        }
    }
    isStaffMemberActiveForEvent(staffMember, event) {
        const selectedStaff = event.selectedStaff ?? {};
        return selectedStaff[staffMember.uid] ?? false;
    }
    isStaffMemberActiveForSelectedEvent(staffMember) {
        return this.isStaffMemberActiveForEvent(staffMember, this.selectedCalendarEvent);
    }
    toggleStaffMemberActiveForSelectedEvent(staffMember) {
        const selectedStaff = this.selectedCalendarEvent.selectedStaff ?? {};
        selectedStaff[staffMember.uid] = !selectedStaff[staffMember.uid] ?? true;
        this.selectedCalendarEvent.selectedStaff = selectedStaff;
    }
    isStaffMemberAvailableForEvent(staffMember, event) {
        const selectedStaff = event.availableStaff ?? {};
        return selectedStaff[staffMember.uid] ?? false;
    }
    isStaffMemberAvailableForSelectedEvent(staffMember) {
        return this.isStaffMemberAvailableForEvent(staffMember, this.selectedCalendarEvent);
    }
    onStaffUpdate(staffID) {
        const selectedStaff = this.selectedCalendarEvent.selectedStaff ?? {};
        const newValue = !selectedStaff[staffID] ?? true;
        selectedStaff[staffID] = newValue;
        this.selectedCalendarEvent.selectedStaff = selectedStaff;
    }
    setCalendarEvent(event) {
        this.isNewCalendarEvent = false;
        this.selectedCalendarEvent = event;
    }
    setName(name) {
        if (this.selectedCalendarEvent) {
            this.selectedCalendarEvent.name = name;
        }
    }
    setDate(date) {
        if (this.selectedCalendarEvent) {
            this.selectedCalendarEvent.date = date.toISOString();
        }
    }
    setCalendarEvents() {
        const query = firestore_1.FirestorePath.Observe.calendarEvents(this.rootStore.userID);
        return (0, index_web_1.observeFilteredElements)(query.path, (0, firestore_1.CALENDAR_EVENTS_QUERY)(), (events) => {
            console.log("CalendarStore setCalendarEvents callback", events);
            this.calendarEvents = events ?? [];
        });
    }
    setHasRequests() {
        console.log("CalendarStore setHasRequests");
        const query = firestore_1.FirestorePath.Observe.availabilityRequests(this.rootStore.userID);
        return (0, index_web_1.observeFilteredElements)(query.path, (0, firestore_1.HAS_ANY_OPEN_REQUEST_QUERY)(), async (result) => {
            console.log("CalendarStore setHasRequests callback", result);
            this.hasOpenRequests = result.length > 0;
            console.log("CalendarStore setHasRequests hasOpenRequests", this.hasOpenRequests);
        });
    }
    onNewCalendarEvent() {
        if (!this.rootStore.staffStore.assureStaff()) {
            return;
        }
        this.isNewCalendarEvent = true;
        const date = utils_1.Utils.getDate();
        this.selectedCalendarEvent = {
            uid: utils_1.Utils.getUUID(),
            name: "",
            isDeleted: false,
            date,
            timestamp: firestore_2.Timestamp.fromDate(new Date(date)),
            selectedStaff: {},
            availableStaff: {},
        };
    }
    hasStaffForEvent() {
        const { selectedStaff } = this.selectedCalendarEvent;
        return Object.values(selectedStaff ?? {}).some(Boolean);
    }
    async storeSelectedCalendarEvent() {
        if (this.selectedCalendarEvent.name.length < 2) {
            (0, alert_1.StdAlert)("Naming Error", "Name needs at least 3 characters!");
            return;
        }
        if (!this.hasStaffForEvent()) {
            (0, alert_1.StdAlert)("Staff Error", "Need at least 1 Staff Member!");
            return;
        }
        const query = firestore_1.FirestorePath.Store.calendarEvent(this.rootStore.userID, this.selectedCalendarEvent.uid);
        this.selectedCalendarEvent.timestamp = firestore_2.Timestamp.fromDate(new Date(this.selectedCalendarEvent.date));
        await (0, index_web_1.setElement)(query.path, this.selectedCalendarEvent);
        this.selectedCalendarEvent = undefined;
        this.rootStore.toastStore.showToast("Successfully Saved");
    }
    unsetSelectedEvent() {
        this.selectedCalendarEvent = undefined;
        this.rootStore.routeTo("calendar");
    }
    async deleteSelectedEvent() {
        console.log("deleteSelectedEvent");
        const didConfirm = await this.rootStore.requestDeleteConfirmation();
        console.log("deleteSelectedEvent didConfirm", didConfirm);
        if (didConfirm) {
            this.selectedCalendarEvent.isDeleted = true;
            const query = firestore_1.FirestorePath.Store.calendarEvent(this.rootStore.userID, this.selectedCalendarEvent.uid);
            await (0, index_web_1.setElement)(query.path, this.selectedCalendarEvent);
            this.selectedCalendarEvent = undefined;
        }
    }
}
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], CalendarStore.prototype, "calendarEvents", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], CalendarStore.prototype, "selectedCalendarEvent", void 0);
__decorate([
    mobx_1.observable
], CalendarStore.prototype, "isNewCalendarEvent", void 0);
__decorate([
    mobx_1.observable
], CalendarStore.prototype, "hasOpenRequests", void 0);
exports.CalendarStore = CalendarStore;
