"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagesStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const firestore_1 = require("../types/firestore");
const index_web_1 = require("../shared/firebase/index.web");
const utils_1 = require("../utils/utils");
const alert_1 = require("../shared/alert");
const firestore_2 = require("firebase/firestore");
class MessagesStore {
    selectedMessage;
    newMessage;
    messages = [];
    isComposing = false;
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("MessagesStore constructor", this.rootStore);
    }
    setMessages() {
        const query = firestore_1.FirestorePath.Observe.messages(this.rootStore.userID);
        console.log("MessagesStore setMessages", query);
        return (0, index_web_1.observeElements)(query.path, (messages) => {
            console.log("MessagesStore setMessages callback", messages);
            this.messages = messages ?? [];
        });
    }
    onSelect(message) {
        console.log("MessagesStore message", message);
        this.selectedMessage = message;
    }
    setMessage(text) {
        if (this.newMessage) {
            this.newMessage.text = text;
        }
        if (this.selectedMessage) {
            this.selectedMessage.text = text;
        }
    }
    compose() {
        console.log("MessagesStore compose");
        const date = utils_1.Utils.getDate();
        this.newMessage = {
            uid: utils_1.Utils.getUUID(),
            date,
            text: '',
            timestamp: firestore_2.Timestamp.fromDate(new Date(date)),
            status: 'pending'
        };
        this.isComposing = true;
    }
    abortCompose() {
        this.newMessage = undefined;
        this.isComposing = false;
    }
    checkout() {
        console.log("MessagesStore checkout");
        if (this.newMessage.text === '') {
            (0, alert_1.StdAlert)('Error', 'Your message is empty');
            return;
        }
        // if (this.rootStore.leadsStore.leads.length < 3) {
        //     StdAlert('Error', 'Our payment processor requires a minimum purchase value. You need at least 3 leads in your database.')
        //     return
        // }
        const userID = this.rootStore.userID;
        const returnURL = this.getReturnURL(this.newMessage.uid);
        console.log("returnURL", returnURL);
        this.rootStore.isLoading = true;
        (0, index_web_1.requestOnCall)('getCheckoutSession', {
            userID,
            returnURL,
            associatedID: this.newMessage.uid,
            message: this.newMessage.text,
            isProduction: true,
        }).then((response) => {
            console.log('MessagesStore checkout getCheckoutSession response', response);
            this.rootStore.isLoading = false;
            const _response = response;
            if (_response.error) {
                console.error('MessagesStore checkout getCheckoutSession error response', _response.error);
                (0, alert_1.StdAlert)('Error', _response.error.error);
                return;
            }
            const url = _response.success?.metadata.url ?? '';
            console.log('MessagesStore checkout getCheckoutSession success response', response, url);
            window.open(url, '_blank');
        }).catch((error) => {
            console.error(error);
            this.rootStore.isLoading = false;
            (0, alert_1.StdAlert)('Error', `${error}`);
        });
    }
    getReturnURL(messageID) {
        let baseURL = 'https://app.cya.management';
        if (typeof window !== 'undefined') {
            const host = window.location.hostname;
            const port = window.location.port ? `:${window.location.port}` : '';
            baseURL = `${window.location.protocol}//${host}${port}`;
        }
        const url = `${baseURL}/messages?uid=${messageID}`;
        console.log('MessagesStore getReturnURL', url);
        return url;
    }
    unsubscribe() {
        this.messages = [];
        this.selectedMessage = undefined;
        this.newMessage = undefined;
    }
}
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], MessagesStore.prototype, "selectedMessage", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], MessagesStore.prototype, "newMessage", void 0);
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], MessagesStore.prototype, "messages", void 0);
__decorate([
    mobx_1.observable
], MessagesStore.prototype, "isComposing", void 0);
exports.MessagesStore = MessagesStore;
