"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonTitleStyle = exports.ButtonStyle = exports.H1Style = exports.TextStyleInfo = exports.TextStyleError = exports.TextStyleBase = exports.InputStyle = exports.TabBarIconInnerStyle = exports.TabBarIconStyle = exports.TabBarStyle = exports.ContentBox = exports.ContainerStyleMobile = exports.ContainerStyleWeb = exports.CheckmarkWhite = exports.CheckmarkPurple = void 0;
const react_native_1 = require("react-native");
exports.CheckmarkPurple = require(`../../../icons/icons8-instagram_check_mark_purple.png`);
exports.CheckmarkWhite = require(`../../../icons/icons8-instagram_check_mark.png`);
// const DarkTheme: Theme = {
//     ContainerColor: "#121212",
//     ContentBoxColor: "#212121",
//     TextColor: 'white',
//     TextColorInfo: "#D3D3D390",
//     TextErrorColor: "red"
//     ButtonColor: "white"
// }
const LightTheme = {
    ContainerColor: '#000009',
    ContentBoxColor: '#464F5150',
    TabBarIconColor: '#464F5150',
    TextColor: '#EEF0F2',
    TextColorInfo: '#DEFFF2',
    TextErrorColor: 'red',
    ButtonColor: '#0FF4C6',
    ShadowColor: "#D68FD6"
};
const CurrentTheme = LightTheme;
const BaseShadow = {
    shadowColor: CurrentTheme.ShadowColor,
    shadowOffset: {
        width: 2,
        height: 4,
    },
    shadowOpacity: .4,
    shadowRadius: 3,
};
exports.ContainerStyleWeb = {
    height: `calc(${react_native_1.Dimensions.get("window").height}px - 16px)`,
    width: "calc(50vw - 16px)",
    backgroundColor: CurrentTheme.ContainerColor,
    padding: "16px",
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
    overflow: 'scroll'
};
exports.ContainerStyleMobile = {
    width: "calc(100vw - 60px - 16px)"
};
exports.ContentBox = {
    padding: '16px',
    backgroundColor: CurrentTheme.ContentBoxColor,
    borderRadius: 16,
    marginBottom: 8,
    ...BaseShadow
};
exports.TabBarStyle = {
    width: "60px",
    height: react_native_1.Dimensions.get("window").height,
    justifyContent: "flex-start",
    backgroundColor: CurrentTheme.ContainerColor,
    ...BaseShadow
};
exports.TabBarIconStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
};
exports.TabBarIconInnerStyle = {
    backgroundColor: CurrentTheme.TabBarIconColor,
    height: "44px",
    width: "44px",
    borderRadius: 22,
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
};
exports.InputStyle = {
    marginTop: "4px",
    marginBottom: "16px",
    padding: "8px",
    height: "44px",
    borderColor: "#121212",
    borderWidth: 1,
    backgroundColor: CurrentTheme.ContentBoxColor,
    borderRadius: 4,
    color: "white",
};
exports.TextStyleBase = {
    fontSize: 16,
    fontWeight: '500',
    paddingTop: "8px",
    paddingBottom: "4px",
    marginBottom: "8px",
    color: CurrentTheme.TextColor
};
exports.TextStyleError = {
    color: CurrentTheme.TextErrorColor,
};
exports.TextStyleInfo = {
    fontSize: 14,
    color: CurrentTheme.TextColorInfo,
};
exports.H1Style = {
    fontWeight: "bold",
    fontSize: 28,
    marginBottom: "32px",
    marginTop: 4,
    color: CurrentTheme.TextColor,
};
exports.ButtonStyle = {
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: CurrentTheme.ButtonColor,
    height: 44,
    ...BaseShadow
};
exports.ButtonTitleStyle = {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: 'black',
};
