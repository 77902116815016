"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const root_store_1 = require("../stores/root-store");
const LoadingOverlay = (0, mobx_react_lite_1.observer)(() => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    return (react_1.default.createElement(react_native_1.Modal, { visible: rootStore.isLoading, transparent: true, animationType: "none" },
        react_1.default.createElement(react_native_1.View, { style: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.8)',
            } },
            react_1.default.createElement(react_native_1.View, { style: {
                    borderRadius: 10,
                    backgroundColor: 'white',
                    padding: 25,
                    alignItems: 'center',
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                } },
                react_1.default.createElement(react_native_1.ActivityIndicator, { size: "large", color: "#0000ff" }),
                react_1.default.createElement(react_native_1.Text, { style: { marginTop: 10 } }, "Loading...")))));
});
exports.default = LoadingOverlay;
