"use strict";
// Creating `StoreQuery` here.
Object.defineProperty(exports, "__esModule", { value: true });
exports.CALENDAR_EVENTS_QUERY = exports.HAS_ANY_OPEN_REQUEST_QUERY = exports.FirestorePath = void 0;
const firestore_1 = require("firebase/firestore");
class FirestorePath {
    // To upload
    static Store = new (class {
        staffMember(userID, staffID) {
            return {
                path: ["Staff", userID, "StaffMember", staffID],
            };
        }
        calendarEvent(userID, eventID) {
            return {
                path: ["Events", userID, "CalendarEvent", eventID],
            };
        }
    })();
    // To download
    static Observe = new (class {
        messages(userID) {
            return {
                path: ["BlastMessages", userID, "Message"],
            };
        }
        staff(userID) {
            return {
                path: ["Staff", userID, "StaffMember"],
            };
        }
        staffMember(userID, clientID) {
            return {
                path: ["Staff", userID, "StaffMember", clientID],
            };
        }
        sortedStaff(userID) {
            return {
                path: ["SortedStaff", userID],
            };
        }
        activity(userID) {
            return {
                path: ["Activity", userID, "Activity"],
            };
        }
        venueRating(userID, venueID) {
            return {
                path: ["VenueRatings", userID, "Venues", venueID, "Ratings"],
            };
        }
        leads(userID) {
            return {
                path: ["Leads", userID, "Lead"],
            };
        }
        venueOpened(userID, venueID) {
            return {
                path: ["VenueOpened", userID, "Venues", venueID],
            };
        }
        calendarEvents(userID) {
            return {
                path: ["Events", userID, "CalendarEvent"],
            };
        }
        availabilityRequests(userID) {
            return {
                path: ["AvailabilityRequests", userID, "Requests"],
            };
        }
    })();
}
exports.FirestorePath = FirestorePath;
const HAS_ANY_OPEN_REQUEST_QUERY = () => {
    return [
        (0, firestore_1.where)("hasOpenRequest", "==", true),
        (0, firestore_1.limit)(1)
    ];
};
exports.HAS_ANY_OPEN_REQUEST_QUERY = HAS_ANY_OPEN_REQUEST_QUERY;
const CALENDAR_EVENTS_QUERY = () => {
    return [
        (0, firestore_1.where)("isDeleted", "!=", true)
    ];
};
exports.CALENDAR_EVENTS_QUERY = CALENDAR_EVENTS_QUERY;
