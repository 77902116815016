"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
class ActivityStore {
    events = [];
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("ActivityStore constructor", rootStore);
    }
    setActivities() {
        const query = firestore_1.FirestorePath.Observe.activity(this.rootStore.userID);
        return (0, index_web_1.observeElements)(query.path, (events) => {
            console.log("ActivityStore setActivity callback", events);
            this.events = events.filter((event) => {
                return !event.isDeleted;
            });
        });
    }
    onSelect(activity) {
        console.log("ActivityStore onSelect", activity);
        // if (activity.routeParams?.reviewID) {
        //   this.rootStore.staffStore.setStaffID(activity.routeParams!.reviewID);
        //   return;
        // }
    }
    unsubscribe() {
        this.events = [];
    }
}
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], ActivityStore.prototype, "events", void 0);
exports.ActivityStore = ActivityStore;
