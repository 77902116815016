"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootStoreContext = exports.RootStore = void 0;
const mobx_persist_1 = require("mobx-persist");
const react_native_1 = require("react-native");
const activity_store_1 = require("./activity-store");
const auth_store_1 = require("./auth-store");
const staff_store_1 = require("./staff-store");
const menu_store_1 = require("./menu-store");
const mobx_1 = require("mobx");
const toast_store_1 = require("./toast-store");
const react_1 = require("react");
const calendar_store_1 = require("./calendar-store");
const alert_1 = require("../shared/alert");
const reviews_store_1 = require("./reviews-store");
const utils_1 = require("../utils/utils");
const messages_store_1 = require("./messages-store");
const leads_store_1 = require("./leads-store");
const hydrate = (0, mobx_persist_1.create)({
    storage: react_native_1.AsyncStorage,
    jsonify: true,
});
class RootStore {
    routeCallback;
    subscriptions = [];
    menuStore = new menu_store_1.MenuStore(this);
    authStore = new auth_store_1.AuthStore(this);
    staffStore = new staff_store_1.StaffStore(this);
    messagesStore = new messages_store_1.MessagesStore(this);
    leadsStore = new leads_store_1.LeadsStore(this);
    activityStore = new activity_store_1.ActivityStore(this);
    toastStore = new toast_store_1.ToastStore(this);
    calendarStore = new calendar_store_1.CalendarStore(this);
    reviewsStore = new reviews_store_1.ReviewsStore(this);
    userID;
    isLoading = false;
    version;
    constructor() {
        hydrate("staffStore", this.staffStore);
        hydrate("activityStore", this.activityStore);
        this.authStore.observerUserChanges((user) => {
            this.unsubscribe();
            this.userID = undefined;
            if (user) {
                this.userID = user.uid;
                this.subscriptions.push(this.staffStore.setStaff());
                this.subscriptions.push(this.staffStore.setSortedStaff());
                this.subscriptions.push(this.activityStore.setActivities());
                this.subscriptions.push(this.staffStore.setSortedStaff());
                this.subscriptions.push(this.messagesStore.setMessages());
                this.subscriptions.push(this.leadsStore.setLeads());
                this.subscriptions.push(this.staffStore.setSortedStaff());
                this.subscriptions.push(this.calendarStore.setCalendarEvents());
                this.subscriptions.push(this.calendarStore.setHasRequests());
                this.subscriptions.push(this.reviewsStore.setRatings());
            }
        });
        (0, mobx_1.makeAutoObservable)(this);
    }
    getTimestamp(dateEvent) {
        return `${utils_1.Utils.timeSince(dateEvent.date)} ago`;
    }
    setBuildVersion(version) {
        this.version = version;
    }
    routeTo(route) {
        if (this.routeCallback) {
            console.log("RootStore routeTo routeCallback exists", route);
            this.routeCallback(route);
        }
        else {
            console.error("Error in RootStore routeTo routeCallback undefined");
        }
    }
    onRoute(callback) {
        console.log("RootStore onRoute setter called");
        this.routeCallback = callback;
    }
    requestDeleteConfirmation() {
        return new Promise((resolve) => {
            (0, alert_1.BinaryAlert)("Delete Confirmation", "Are you sure you want to delete?", () => {
                resolve(true);
            }, () => {
                resolve(false);
            });
        });
    }
    // MARK: - Private
    unsubscribe() {
        for (const subscription of this.subscriptions) {
            subscription();
        }
        this.subscriptions = [];
        this.staffStore.unsubscribe();
        this.activityStore.unsubscribe();
        this.leadsStore.unsubscribe();
        this.reviewsStore.unsubscribe();
        this.messagesStore.unsubscribe();
    }
}
__decorate([
    mobx_1.observable
], RootStore.prototype, "userID", void 0);
__decorate([
    mobx_1.observable
], RootStore.prototype, "isLoading", void 0);
exports.RootStore = RootStore;
exports.RootStoreContext = (0, react_1.createContext)(new RootStore());
