"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaffStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
const utils_1 = require("../utils/utils");
const alert_1 = require("../shared/alert");
class StaffStore {
    selectedStaffMember;
    staff = [];
    sortedStaff = {};
    isNewStaffMember = false;
    staffIDSubscription;
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("StaffStore constructor", rootStore);
    }
    setSortedStaff() {
        const query = firestore_1.FirestorePath.Observe.sortedStaff(this.rootStore.userID);
        console.log("StaffStore setSortedStaff", query);
        return (0, index_web_1.observeElement)(query.path, (sortedStaff) => {
            console.log("StaffStore setSortedStaff callback", sortedStaff);
            this.sortedStaff = sortedStaff ?? {};
        });
    }
    setStaff() {
        const query = firestore_1.FirestorePath.Observe.staff(this.rootStore.userID);
        console.log("StaffStore setStaff", query);
        return (0, index_web_1.observeElements)(query.path, (staff) => {
            console.log("StaffStore setStaff callback", staff);
            this.staff = staff ?? [];
        });
    }
    onNewStaffMember() {
        this.isNewStaffMember = true;
        this.selectedStaffMember = {
            uid: utils_1.Utils.getUUID(),
            name: "",
            email: "",
            isDeleted: false,
            date: utils_1.Utils.getDate(),
        };
    }
    setStaffMember(member) {
        this.isNewStaffMember = false;
        this.selectedStaffMember = member;
    }
    setStaffID(staffID) {
        console.log("StaffStore setStaffID staffID", staffID);
        if (!staffID) {
            return;
        }
        this.unsubscribeStaffID();
        const query = firestore_1.FirestorePath.Observe.staffMember(this.rootStore.userID, staffID);
        console.log("StaffStore setStaffID", query);
        this.staffIDSubscription = (0, index_web_1.observeElement)(query.path, (staffMember) => {
            console.log("StaffStore setStaffID callback", staffMember);
            this.setStaffMember(staffMember);
            this.rootStore.routeTo("staff");
        });
    }
    unsetSelectedStaffMember() {
        this.selectedStaffMember = undefined;
        this.rootStore.routeTo("staff");
    }
    setName(name) {
        if (this.selectedStaffMember) {
            this.selectedStaffMember.name = name;
        }
    }
    setEmail(email) {
        if (this.selectedStaffMember) {
            this.selectedStaffMember.email = email;
        }
    }
    async storeStaffMember() {
        const query = firestore_1.FirestorePath.Store.staffMember(this.rootStore.userID, this.selectedStaffMember.uid);
        await (0, index_web_1.setElement)(query.path, this.selectedStaffMember);
        this.selectedStaffMember = undefined;
        this.rootStore.toastStore.showToast("Successfully Saved");
    }
    hasStaff() {
        return Object.keys(this.sortedStaff).length > 0;
    }
    assureStaff() {
        if (!this.hasStaff()) {
            (0, alert_1.StdAlert)("Need Staff", "You need to create a Staff Member first.");
            return false;
        }
        return true;
    }
    async deleteSelectedStaffMember() {
        console.log("deleteSelectedStaffMember");
        const didConfirm = await this.rootStore.requestDeleteConfirmation();
        console.log("deleteSelectedStaffMember didConfirm", didConfirm);
        if (didConfirm) {
            this.selectedStaffMember.isDeleted = true;
            const query = firestore_1.FirestorePath.Store.staffMember(this.rootStore.userID, this.selectedStaffMember.uid);
            await (0, index_web_1.setElement)(query.path, this.selectedStaffMember);
            this.selectedStaffMember = undefined;
        }
    }
    unsubscribe() {
        this.unsubscribeStaffID();
        this.sortedStaff = {};
        this.staff = [];
    }
    // MARK: - Private
    unsubscribeStaffID() {
        if (this.staffIDSubscription) {
            this.staffIDSubscription();
            this.staffIDSubscription = undefined;
        }
    }
}
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], StaffStore.prototype, "selectedStaffMember", void 0);
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], StaffStore.prototype, "staff", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], StaffStore.prototype, "sortedStaff", void 0);
__decorate([
    mobx_1.observable
], StaffStore.prototype, "isNewStaffMember", void 0);
exports.StaffStore = StaffStore;
