"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = void 0;
exports.environment = {
    firebase: {
        apiKey: "AIzaSyA5MdcaagcuRAfgQ2F8ACHCSkhzY5njqbQ",
        authDomain: "cya-app-fa34a.firebaseapp.com",
        projectId: "cya-app-fa34a",
        storageBucket: "cya-app-fa34a.appspot.com",
        messagingSenderId: "845984776219",
        appId: "1:845984776219:web:cc6c0a13cb32612174b8be",
        measurementId: "G-8WP80KCVWY",
    },
};
