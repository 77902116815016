"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewsStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
class ReviewsStore {
    events = [];
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("ReviewsStore constructor", rootStore);
    }
    setRatings() {
        const query = firestore_1.FirestorePath.Observe.venueRating(this.rootStore.userID, this.getVenueID());
        return (0, index_web_1.observeElements)(query.path, (events) => {
            console.log("ReviewsStore setRatings callback", events);
            this.events = events;
        });
    }
    onSelect(activity) {
        console.log("ReviewsStore onSelect", activity);
        if (activity.routeParams?.reviewID) {
            // this.rootStore.staffStore.setStaffID(activity.routeParams!.staffID);
            return;
        }
    }
    unsubscribe() {
        this.events = [];
    }
    getQRURI() {
        if (this.rootStore.authStore.user) {
            const uid = this.rootStore.authStore.user.uid;
            return `https://chat.cya.management?uid=${uid}`;
        }
        return '';
    }
    openQRURI() {
        window.open(this.getQRURI(), '_blank');
    }
    getVenueID() {
        if (this.rootStore.authStore.user) {
            const uid = this.rootStore.authStore.user.uid;
            return `${uid}-default`;
        }
        return '';
    }
}
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], ReviewsStore.prototype, "events", void 0);
exports.ReviewsStore = ReviewsStore;
