"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BinaryAlert = exports.StdAlert = void 0;
const StdAlert = (title, desc, onPress = () => { }) => {
    alert(`${title}\n${desc}`);
    if (onPress)
        onPress();
};
exports.StdAlert = StdAlert;
const BinaryAlert = (title, desc, onPositivePress = () => { }, onNegativePress = () => { }) => {
    const res = window.confirm(`${title}\n${desc}`);
    if (res)
        onPositivePress();
    else
        onNegativePress();
};
exports.BinaryAlert = BinaryAlert;
