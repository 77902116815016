"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthPage = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const root_store_1 = require("../stores/root-store");
const h1_1 = require("../components/h1");
const input_header_1 = require("../components/input-header");
const input_1 = require("../components/input");
const action_button_1 = require("../components/action-button");
const labels_1 = require("../components/labels");
const page_container_1 = require("../components/page-container");
const alert_1 = require("../shared/alert");
exports.AuthPage = (0, mobx_react_lite_1.observer)(() => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const Email = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(input_header_1.InputHeader, { title: "Email" }),
        react_1.default.createElement(input_1.Input, { value: rootStore.authStore.email, placeholder: "your@email.com", onChange: (text) => rootStore.authStore.setEmail(text) })));
    const Password = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(input_header_1.InputHeader, { title: "Password" }),
        react_1.default.createElement(input_1.Input, { secureTextEntry: true, value: rootStore.authStore.password, placeholder: "******", onChange: (text) => rootStore.authStore.setPassword(text) })));
    const Error = (react_1.default.createElement(react_native_1.View, null, rootStore.authStore.error ? (react_1.default.createElement(labels_1.ErrorLabel, { text: rootStore.authStore.error })) : null));
    const Actions = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(action_button_1.ActionButton, { title: "Login", onPress: () => rootStore.authStore.login() }),
        react_1.default.createElement(action_button_1.ActionButton, { title: "Register", onPress: () => {
                //rootStore.authStore.register()
                (0, alert_1.StdAlert)("Error", "We're currently in private Beta. Sign-up is not yet supported.");
            } })));
    const Content = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(h1_1.H1, { text: "Welcome" }),
        Email,
        Password,
        Error,
        Actions));
    return react_1.default.createElement(page_container_1.ViewWithoutTabBar, { view: Content });
});
