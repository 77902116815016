"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuStore = void 0;
const mobx_1 = require("mobx");
class MenuStore {
    activeRoute = "activity";
    menuItems = [];
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        this.setItems("activity", false);
    }
    setItems(newRoute, doRoute) {
        console.log("MenuStore setItems", newRoute, doRoute);
        this.activeRoute = newRoute;
        if (doRoute) {
            this.rootStore.routeTo(newRoute);
        }
        this.menuItems = [
            {
                route: "activity",
                isActive: newRoute === "activity",
                onPress: () => {
                    this.setItems("activity", true);
                },
            },
            {
                route: "reviews",
                isActive: newRoute === "reviews",
                onPress: () => {
                    this.setItems("reviews", true);
                },
            },
            {
                route: "leads",
                isActive: newRoute === "leads",
                onPress: () => {
                    this.setItems("leads", true);
                },
            },
            {
                route: "messages",
                isActive: newRoute === "messages",
                onPress: () => {
                    this.setItems("messages", true);
                },
            },
            // {
            //   route: "staff",
            //   isActive: newRoute === "staff",
            //   onPress: () => {
            //     this.setItems("staff", true);
            //   },
            // },
            {
                route: "settings",
                isActive: newRoute === "settings",
                onPress: () => {
                    this.setItems("settings", true);
                },
            },
        ];
    }
}
__decorate([
    mobx_1.observable
], MenuStore.prototype, "activeRoute", void 0);
__decorate([
    mobx_1.observable
], MenuStore.prototype, "menuItems", void 0);
exports.MenuStore = MenuStore;
