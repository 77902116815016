"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadItem = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const react_native_1 = require("react-native");
const root_store_1 = require("../stores/root-store");
const labels_1 = require("./labels");
const theme_1 = require("../utils/theme");
exports.LeadItem = (0, mobx_react_lite_1.observer)((props) => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const LeadText = (lead) => (react_1.default.createElement(labels_1.TextLabel, { key: lead.uid, text: `${lead.phoneNumber} ${lead.name ? `    ${lead.name}` : ""}` }));
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { key: props.lead.uid, style: theme_1.ContentBox, onPress: () => rootStore.leadsStore.onSelect(props.lead) }, LeadText(props.lead)));
});
