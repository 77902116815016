"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewItem = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const react_native_1 = require("react-native");
const root_store_1 = require("../stores/root-store");
const theme_1 = require("../utils/theme");
const labels_1 = require("./labels");
exports.ReviewItem = (0, mobx_react_lite_1.observer)((props) => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const TimeStamp = (review) => (react_1.default.createElement(labels_1.InfoLabel, { key: review.uid + "date", text: `${rootStore.getTimestamp(review)} ${review.name ? `by ${review.name}` : ''} ${review.phoneNumber ? `(${review.phoneNumber})` : ''}` }));
    const Text_ = (review) => (react_1.default.createElement(labels_1.TextLabel, { key: review.uid, text: review.feedbackText }));
    const Rating = (review) => {
        const rating = parseInt(review.rating, 0);
        const active = {
            color: 'gold'
        };
        const passive = {
            color: 'gray'
        };
        const start1Style = rating <= 0 ? passive : active;
        const start2Style = rating <= 1 ? passive : active;
        const start3Style = rating <= 2 ? passive : active;
        const start4Style = rating <= 3 ? passive : active;
        const start5Style = rating <= 4 ? passive : active;
        return (react_1.default.createElement("div", { id: "starContainer", style: { marginBottom: 8 } },
            react_1.default.createElement("span", { style: start1Style }, "\u2606"),
            react_1.default.createElement("span", { style: start2Style }, "\u2606"),
            react_1.default.createElement("span", { style: start3Style }, "\u2606"),
            react_1.default.createElement("span", { style: start4Style }, "\u2606"),
            react_1.default.createElement("span", { style: start5Style }, "\u2606")));
    };
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { key: props.review.uid, style: theme_1.ContentBox, onPress: () => rootStore.reviewsStore.onSelect(props.review) },
        TimeStamp(props.review),
        Rating(props.review),
        Text_(props.review)));
});
