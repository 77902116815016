import { AppRegistry } from "react-native";
import { App } from "@cya/common";
import pkg from '../package.json';
const { version } = pkg;

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  initialProps: { buildVersion: version },
  rootTag: document.getElementById("root"),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
